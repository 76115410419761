import '@/configs/firebase'
import useDirection from '@/utils/hooks/useDirection'
import useLocale from '@/utils/hooks/useLocale'
import { useAuth, useOrganization } from '@clerk/clerk-react'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { Suspense, lazy, useEffect, useMemo } from 'react'
import ChooseOrgPage from './ChooseOrgPage'

const Layout = () => {
  const isOrg = !!useOrganization().organization
  const organizationId = useOrganization().organization?.id

  const ldClient = useLDClient()
  useEffect(() => {
    if (organizationId && ldClient) {
      ldClient.identify({ kind: 'organization', key: organizationId, name: organizationId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  useEffect(() => {
    const signInWithClerk = async () => {
      const auth = getAuth()
      const token = await getToken({ template: 'integration_firebase' })
      await signInWithCustomToken(auth, token!)
    }

    signInWithClerk()

    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getToken, isSignedIn } = useAuth()
  useDirection()

  useLocale()

  const AppLayout = useMemo(() => {
    if (isSignedIn) {
      if (location.href.includes('onboarding')) {
        return lazy(() => import('./AuthLayout'))
      }
      return lazy(() => import('./ModernLayout'))
    }
    return lazy(() => import('./AuthLayout'))
  }, [isSignedIn])

  if (!isOrg && isSignedIn) {
    return <ChooseOrgPage />
  }
  return (
    <Suspense fallback={<></>}>
      <AppLayout />
    </Suspense>
  )
}

export default Layout
