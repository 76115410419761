/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import '@/assets/styles/drawerStyles.css'
import '@/assets/styles/markdowBodyStyles.css'
import '@/assets/styles/nextUITableStyles.css'
import '@/assets/styles/reactBigCalendarStyles.css'
import Layout from '@/components/layouts'
import Theme from '@/components/template/Theme'
import { ClerkProvider } from '@clerk/clerk-react'
import { dark } from '@clerk/themes'
import { NextUIProvider } from '@nextui-org/react'
import * as Sentry from '@sentry/react'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import ReactQueryProvider from './Providers/ReactQueryProvider'
import './customCss.css'
import useNetworkStatus from './customHooks/useNetworkStatus'
import './index.css'
import './locales'
import store, { persistor } from './store'
import useDarkMode from './utils/hooks/useDarkmode'
import NoNetwork from './views/NoNetwork/NoNetwork'
import { tracker } from './utils/openReplayUtils'

Sentry.init({
  dsn: 'https://f5f857e67575c4d3088f48ab6094d25d@o4507606113779712.ingest.us.sentry.io/4507617154957312',
  integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const suppressedWarnings = ['reaviz', 'defaultProps']

// Todo we need to remove reavize library, because it has deprecated functionality
// This library has only 8k weekly download and only 1k stars, this library has more vulnarabilities
const originalWarn = console.warn

// remove all warning comes from reavize lib
console.warn = function (message, ...args) {
  if (!suppressedWarnings.some((warning) => message.includes(warning))) {
    originalWarn.apply(console, [message, ...args])
  }
}

// remove all errors that comes from reavize lib
//   if (!suppressedWarnings.some((warning) => message.includes(warning))) {
//     originalWarn.apply(console, [message, ...args])
//   }
// }

if (!import.meta.env.VITE_REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key')
}
// const clerkPubKey = import.meta.env.VITE_REACT_APP_CLERK_PUBLISHABLE_KEY
const clerkPubKey = import.meta.env.VITE_REACT_APP_CLERK_PUBLISHABLE_KEY

function App() {
  const [_, setIsDarkMode] = useState(false)
  useEffect(() => {
    const darkMode = localStorage.getItem('theme') === 'dark'
    setIsDarkMode(darkMode)

    //create event listener for theme change
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
      setIsDarkMode(e.matches)
    })
  }, [])

  return (
    <Provider store={store}>
      <CustomWrapper />
    </Provider>
  )
}

export default App

const CustomWrapper = () => {
  const [isDarkMode] = useDarkMode()
  const isOnline = useNetworkStatus()

  useEffect(() => {
    window.addEventListener('online', () => window.location.reload())
  }, [])

  if (!isOnline) {
    return <NoNetwork />
  }

  //refresh the page if the user is offline

  return (
    <ClerkProvider
      appearance={{
        baseTheme: isDarkMode ? dark : undefined,
        variables: {
          colorBackground: isDarkMode ? '#1f2937' : '#ffffff',
        },
      }}
      publishableKey={clerkPubKey}
    >
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ReactQueryProvider>
            <Theme>
              <NextUIProvider>
                <Root />
                {/* <Layout /> */}
                <Analytics />
                <SpeedInsights />
              </NextUIProvider>
            </Theme>
          </ReactQueryProvider>
        </BrowserRouter>
      </PersistGate>
    </ClerkProvider>
  )
}

const Root = withLDProvider({ clientSideID: import.meta.env.VITE_REACT_APP_LAUNCH_DARKLY_CLIENT_ID })(Layout)
