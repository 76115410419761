import { cn } from '@nextui-org/react'
import Lottie from 'lottie-react'
import noInternetAnimation from '@/assets/lottieAnimations/noInternetAnimation.json'

const NoNetwork = () => {
    const isDarkMode = localStorage.getItem('theme') === 'dark'
    return (
        <div
            className={cn(
                'absolute h-dvh w-dvw flex items-center justify-center flex-col gap-2',
                {
                    'bg-gray-900': isDarkMode,
                }
            )}
        >
            <div className="flex flex-col pb-8 px-8 items-center justify-center">
                <Lottie
                    className="h-60 w-60"
                    animationData={noInternetAnimation}
                    loop={false}
                />
                <h1 className="pb-2 text-primary-500">Oops!</h1>
                <h3
                    className={cn('pb-2 font-medium', {
                        'text-white': isDarkMode,
                    })}
                >
                    Looks like you have no internet.
                </h3>
                <h5
                    className={cn('pb-2 font-medium', {
                        'text-white': isDarkMode,
                    })}
                >
                    Please check your connection
                </h5>
            </div>
        </div>
    )
}

export default NoNetwork
