// Import the functions you need from the SDKs you need
import { captureException } from '@sentry/react'
import { FirebaseOptions, initializeApp } from 'firebase/app'
import { enableMultiTabIndexedDbPersistence, getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig: FirebaseOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase

export const app = initializeApp(firebaseConfig)

// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app, import.meta.env.VITE_FIREBASE_DB_NAME)
// Enable offline persistence (should be called once in your app initialization)
enableMultiTabIndexedDbPersistence(db).catch((err) => {
  captureException(err)
})
// connectFirestoreEmulator(db, '127.0.0.1', 8080)
// const functions = getFunctions(app)
// connectFunctionsEmulator(functions, '127.0.0.1', 5001)
// const auth = getAuth(app)
// connectAuthEmulator(auth, 'http://localhost:9099')

// signInWithCredential(
//   auth,
//   GoogleAuthProvider.credential('{"sub": "abc123", "email": "foo@example.com", "email_verified": true}'),
// )
