import React, { useEffect, useState } from 'react'
import Logo from '../template/Logo'
import { OrganizationList } from '@clerk/clerk-react'
import { motion } from 'framer-motion'

const ChooseOrgPage = () => {
    const [showLogo, setShowLogo] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShowLogo(true)
        }, 700)
    }, [])
    return (
        <div className="w-dwh h-dvh flex justify-center items-center">
            {showLogo && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="absolute top-10 left-10"
                >
                    {/* <Logo /> */}
                </motion.div>
            )}
            <OrganizationList hidePersonal={true} />
        </div>
    )
}

export default ChooseOrgPage
